import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBon9mkGjbhBZdyzjqCjy4ycP70aGkx1KQ",
  authDomain: "fireblogsgk-d978f.firebaseapp.com",
  projectId: "fireblogsgk-d978f",
  storageBucket: "fireblogsgk-d978f.appspot.com",
  messagingSenderId: "904766130171",
  appId: "1:904766130171:web:cb6a5a802008005dab6d5e"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();